import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'

var footerlocation = new Swiper(".foot3 .location-t .mySwiper", {
  allowTouchMove: false,
  effect : 'fade',
  autoHeight: true,
  fadeEffect: {
    crossFade: true,
  },
  on: {
    init: function(swiper){
      $('.foot3 .location-tab div').eq(this.activeIndex).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('.foot3 .location-tab div').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
    },
  },
});

$('.foot3 .location-tab div').hover(function(){
  let index = $(this).index()
  console.log(index)
  footerlocation.slideTo(index)
})


$('.foot-nav .nav-title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active')
    $parent.find('.sub-nav').slideUp()
  }else{
    $parent.addClass('active').siblings().removeClass('active')
    $parent.find('.sub-nav').slideDown()
    $parent.siblings().find('.sub-nav').slideUp()
  }
})