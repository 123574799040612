import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('.hidden-menu').removeClass('active')
    $('body').css({
      'height':'auto',
      'overflow':'auto',
    })
    setTimeout(function(){
      $('.hidden-menu').hide()
    },1000)
  }else{
    $('.hidden-menu').show()
    setTimeout(function(){
      $('header .hamburger').addClass('active')
      $('.hidden-menu').addClass('active')
      $('body').css({
        'height':'100vh',
        'overflow':'hidden',
      })
    },100)
  }
})

$('.hidden-menu .left-nav .nav-title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('show-sub')){
    $parent.removeClass('show-sub')
    $parent.find('.sub-nav').slideUp()
  }else{
    $parent.addClass('show-sub').siblings().removeClass('show-sub')
    $parent.find('.sub-nav').slideDown()
    $parent.siblings().find('.sub-nav').slideUp()
  }
})


let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if(scroH > 100){
    $('header').addClass('scroll')
  }else{
    $('header').removeClass('scroll')
  }

  if($('.comp-sub-nav').length > 0){
    let subnavTop = $('.comp-sub-nav').offset().top
    if(scroH > subnavTop){
      $('.comp-sub-nav').addClass('fixed')
    }else{
      $('.comp-sub-nav').removeClass('fixed')
    }
  }
  if($('.comp-sub-nav-three').length > 0){
    let subnavTop = $('.comp-sub-nav-three').offset().top - $('.comp-sub-nav').outerHeight()
    if(scroH > subnavTop){
      $('.comp-sub-nav-three').addClass('fixed')
    }else{
      $('.comp-sub-nav-three').removeClass('fixed')
    }
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0 && scroH > 200) {
    $('.header').addClass('trans')
  }
  if (delta < 0) {
    $('.header').removeClass('trans')
  }

  if($('.comp-sub-nav').length > 0){
    let subnavTop = $('.comp-sub-nav').offset().top
    if(scroH > subnavTop){
      if (delta > 0) {
        $('.comp-sub-nav').removeClass('trans')
      }else if (delta < 0) {
        $('.comp-sub-nav').addClass('trans')
      }
    }else{
      $('.comp-sub-nav').removeClass('trans')
    }
  }

  if($('.comp-sub-nav-three').length > 0){
    let subnavTop = $('.comp-sub-nav').offset().top
    if(scroH > subnavTop){
      if (delta > 0) {
        $('.comp-sub-nav-three').removeClass('trans')
      }else if (delta < 0) {
        $('.comp-sub-nav-three').addClass('trans')
      }
    }else{
      $('.comp-sub-nav-three').removeClass('trans')
    }
  }

  beforeScroH = scroH;
});


$(document).ready(function(){
  if($('.comp-sub-nav').length > 0){
    let subheight =  $('.comp-sub-nav .fixed-cont').outerHeight()
    $('.comp-sub-nav').css('height',subheight + 'px')
  }
  if($('.comp-sub-nav-three').length > 0){
    let subheight =  $('.comp-sub-nav-three .fixed-cont').outerHeight()
    $('.comp-sub-nav-three').css('height',subheight + 'px')
  }
})

$(window).resize(function(){
  if($('.comp-sub-nav').length > 0){
    let subheight =  $('.comp-sub-nav .fixed-cont').outerHeight()
    $('.comp-sub-nav').css('height',subheight + 'px')
  }
  if($('.comp-sub-nav-three').length > 0){
    let subheight =  $('.comp-sub-nav-three .fixed-cont').outerHeight()
    $('.comp-sub-nav-three').css('height',subheight + 'px')
  }
})