import './index.scss'



$('.page_jump_btn').on('click',function(e){
  e.preventDefault()
  jumpPage()
})
$('.page_jump_input').keydown(function(event){
  if(event.keyCode==13){
      jumpPage()
  }
});
function jumpPage(){
  var goUrl = $('.page_jump_btn').attr('data-url')
  var maxPage = $('.page_jump_btn').attr('data-maxpage')
  var goPage = $('.page_jump_input').val()
  // console.log(goUrl,maxPage,goPage)
  
  if((/^(\+|-)?\d+$/.test(goPage)) && goPage > 0){
      if(goPage > maxPage){
          alert("请输入正确的页数！");
          return false;
      }
  }else{
      alert("请输入正确的页数！");
      return false;
  }
  
  var newUrl = goUrl.replace(/{id}/g, goPage)
  window.location.href = newUrl+'#md'
}