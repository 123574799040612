module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!desc) var desc = '';
if (iscenter === undefined) var iscenter = false;
;
__p += '\r\n<section class="ny-banner ' +
((__t = ( iscenter ? 't-center':'' )) == null ? '' : __t) +
'">\r\n  <div class="bg" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="t container">\r\n    <h1 class="bold">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n    <div class="desc">' +
((__t = ( desc )) == null ? '' : __t) +
'</div>\r\n  </div>\r\n  <div class="crumbs_a container">\r\n    <a href="#">首页</a><span>/</span>\r\n			<a href="#">新闻中心</a>\r\n  </div>\r\n</section>';

}
return __p
}